import emotionStyled from '@emotion/styled'
import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'

import headerImage from '../../../assets/baby-bathing.jpg';
import babyInTup from '../../../assets/baby-in-tup.png';
import x from '../../../assets/x.png';
import tg from '../../../assets/tg.png';
import ds from '../../../assets/ds.png';
import dt from '../../../assets/dt.png';
import us from '../../../assets/us.png';
import { LinkEx } from '../LockerMenu/styles'; 
import styled from '@emotion/styled';

const BoxWrapper = emotionStyled(Box)`
    background-color: #00AEEC;
    background-image: url(${headerImage});
    background-position-x: center;
    background-size: cover;
    min-height: 100vh;
    background-repeat: no-repeat;
    padding: 80px 0;
` 
export const MainHeading = styled(Typography)`
    font-family:DU_B;  
    -webkit-text-stroke: 2px; 
    -webkit-text-stroke-color: black; 
    color : #FFFB31;
`
function OfficialLinks() {
  return (
    <BoxWrapper>
        <Container maxWidth="lg">
            <Grid container>
                <Grid xs={12} md={5} paddingTop='8%'>
                {/* <Typography variant='h2' fontWeight='bold' color="#FFFB31" marginBottom={0}>OFFICIAL<br />Yella Cutie<br />LINKS</Typography>  */}
                <MainHeading variant='h4' fontSize={{xs:"40px",md:"60px",xl:"60px"}} whiteSpace={{xs:"wrap",lg:"nowrap"}}  marginBottom={0}>OFFICIAL<br />Yella Cutie<br />LINKS</MainHeading>
                <Typography variant="h4" maxWidth={500} fontFamily="PatrickHand">Social & Onchain Data</Typography>
                <Box marginTop={2}>
                    <LinkEx href="https://x.com/YellaCutieToken " className='none' target="blank"><img src={x}  style={{width:"38px",height:"40px"}}/></LinkEx>
                    <LinkEx href="https://t.me/YellaCutieToken" className='none' target="blank"><img src={tg} style={{width:"38px",height:"40px"}} /></LinkEx>
                    <LinkEx href="https://dexscreener.com/ethereum/0x53c6b0bc37b4ed4c17a089ed2286c9c5ce3228b3" className='none' target="blank"><img src={ds} style={{width:"38px",height:"40px"}} /></LinkEx>
                    <LinkEx href="https://www.dextools.io/app/en/ether/pair-explorer/0x53c6b0bc37b4ed4c17a089ed2286c9c5ce3228b3?t=1720465156575" className='none' target="blank"><img src={dt} style={{width:"38px",height:"40px"}} /></LinkEx>
                    <LinkEx href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&inputCurrency=0x6011105ce4da8239c9e4f738f74eed11611222c3" className='none' target="blank"><img src={us} style={{width:"38px",height:"40px"}} /></LinkEx>
                </Box>
                </Grid>
                <Grid xs={12} md={7} textAlign='center'>
                    <img src={babyInTup} style={{width: '100%',height:'auto', marginTop: '28%'}} />
                </Grid>
            </Grid>
        </Container>
    </BoxWrapper>
  )
}

export default OfficialLinks