import * as React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Paragraph from '../../../Paragraph';
import { Accordion } from '@mui/material';    
import styled from "@emotion/styled";  
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const StyledAccordian = styled(Accordion)`
    background: #161616;
    margin-top: 30px;
    border-radius: 25px !important;
    box-shadow: none; 
    border-bottom: 5px solid black;
    & .MuiButtonBase-root{
        border-radius: 25px !important;
        background: #FFFFFF;
            &>.MuiAccordionSummary-content{ 
              display: flex; 
              justify-content: space-between; 
              margin: 0px 20px;
                &>.MuiTypography-root{
                    color:#000000;   
                }
            }
            &>.MuiAccordionSummary-expandIconWrapper{
                &>.MuiSvgIcon-root{
                    color: white;
                }
            }
        }
        &>.MuiCollapse-root{
            &>.MuiCollapse-wrapper{
                &>.MuiCollapse-wrapperInner{
                    &>.MuiAccordion-region{
                        &>.MuiAccordionDetails-root{
                            &>.MuiTypography-root{
                                color:white;
                            }
                        }
                    }
                }
            }
        }
    
`
export default function BasicAccordion(props) {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = () => () => { 
      setExpanded(!expanded);
    };
  return (
    <div>
      <StyledAccordian onChange={handleChange()} sx={{marginTop: expanded?"30px !important":"0px"}}>
        <AccordionSummary
        sx={{alignItems:"center !important"}}
          aria-controls="panel1a-content"
          id="panel1a-header" >
            <Paragraph my="0px" fontFamily="PatrickHand" fontWeight="semibold" fontSize={{xs:"20px",sm:"25px"}} >{props.def1}</Paragraph>   
            { 
              expanded ?   <ArrowUpwardIcon  sx={{fontSize:"24px",borderRadius:"50%",backgroundColor:"black",color:"white"}} /> :   
              <ArrowDownwardIcon sx={{fontSize:"24px",borderRadius:"50%",backgroundColor:"black",color:"white"}} />
            }
       
           
        </AccordionSummary> 
        <AccordionDetails>
          <Paragraph my="0px" sx={{marginRight:{xs:"0px", md:"95px"},fontFamily:'PatrickHand', fontWeight:'400'}}>
            {props.def2} 
          </Paragraph>
        </AccordionDetails>
      </StyledAccordian>
    </div>
  );
}