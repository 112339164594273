import React from 'react'
import Paragraph from '../Paragraph'
import { Box } from '@mui/material'

const Footer = () => {
  return (
    <Box width="100%" display="flex" justifyContent="center" alignItems="center" sx={{backgroundColor:"#FFFB31",padding:"5px 10px"}}> 
        <Paragraph fontSize={{xs:"",lg:"25px"}}>  
        info@yellacutie0x69.com
        </Paragraph>
    </Box>
  )
}

export default Footer