import React from 'react'
import LockerMenu from '../../Components/LockerMenu'
import { DataImage, PageWrapper } from '../../Styles/style'
import Header from '../../Components/Header'
import About from '../../Components/About'
import OfficialLinks from '../../Components/OfficialLinks' 
import Marquee from "react-fast-marquee"; 
import marqueebg from "../../../assets/marquee_Y.png"
import headerImage from '../../../assets/fans.jpg';
import Tokenomics from '../../Components/Tokenomics' 
import HowToBuy from '../../Components/HowToBuy'
import Disclaimer from '../../Components/Disclaimer'
import Footer from '../../Components/Footer'


const MainPage = (props) => {
  return (
    <PageWrapper>
        {/* <LockerMenu /> */}
        <Header /> 
        <Marquee play style={{background:"#FFFB31",overflow:"hidden"}}>  
         <DataImage src={marqueebg} alt="marquee_Img" />
        </Marquee>
        <About />
        <OfficialLinks />
        <img src={headerImage} style={{width: '100%', height: 'auto'}} />
        <Tokenomics /> 
        <HowToBuy /> 
        <Disclaimer /> 
        <Footer />
    </PageWrapper>
  )
}

export default MainPage