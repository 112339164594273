import React from 'react'

import cutieImage from '../../../assets/cutie-baby.png';
import headerImage from '../../../assets/bk.jpg';
import { Box, Container, Grid, Typography } from '@mui/material';
import emotionStyled from '@emotion/styled';
import { LinkEx } from '../LockerMenu/styles'; 
import styled from '@emotion/styled';

const BoxWrapper = emotionStyled(Box)`
    background-color: #00AEEC;
    background-image: url(${headerImage});
    background-position-x: center;
    background-size: contain;
    // min-height: 100vh;
    background-repeat: no-repeat;
    padding-top: 20px;
`
const MainHeading = styled(Typography)`
    font-family:DU_B;  
    -webkit-text-stroke: 2px; 
    -webkit-text-stroke-color: black; 
    color : #FFFB31;
`

function About() {
  return (
    <BoxWrapper>
        <Container maxWidth="lg">
            <Grid container>
                <Grid xs={12} md={7.5} marginBottom={3} paddingTop='50px' paddingBottom="50px">
                    <MainHeading variant='h4' fontSize={{xs:"54px",md:"60px",xl:"60px"}} whiteSpace={{xs:"wrap",lg:"nowrap"}}  marginBottom={3}>A New King Has Risen</MainHeading>
                    <Typography fontFamily="PatrickHand" paddingBottom={{xs:"15px",lg:"20px"}} fontSize={{xs:"26px",lg:"27px"}} lineHeight={{xs:"25px",lg:"37px"}} fontWeight={"500"} letterSpacing={"1px"} maxWidth={850}>
                        Introducing Yella Cutie, the latest sensation in the crypto world inspired by Matt Furie's whimsical and iconic artwork. Yella Cutie isn't just a piece of art; it's a symbol of fun, creativity, and the limitless possibilities of the digital age. This meme coin is set to revolutionize the way we think about cryptocurrency, bringing a burst of humor and joy to every transaction. 
                    </Typography>
                    <Typography fontFamily="PatrickHand" paddingBottom={{xs:"15px",lg:"20px"}} fontSize={{xs:"26px",lg:"27px"}} fontWeight={"500"} letterSpacing={"1px"} lineHeight={{xs:"25px",lg:"37px"}} maxWidth={850}>
                        With Yella Cutie Coin, we're building a vibrant and inclusive community where every holder is part of a grand adventure. Yella Cutie Coin invites you to join us on a journey to the moon and beyond. Embrace the rise of this new king!
                    </Typography>
                    <Typography fontFamily="PatrickHand" paddingBottom={{xs:"15px",lg:"20px"}} fontSize={{xs:"26px",lg:"27px"}} fontWeight={"500"} letterSpacing={"1px"} lineHeight={{xs:"25px",lg:"37px"}} maxWidth={850}>
                        Together, we can make Yella Cutie Coin a cornerstone of the crypto revolution. Join us now and be part of the magic that is Yella Cutie!
                    </Typography>
                    <Box marginTop={2}>
                        {/* <LinkEx href="https://x.com/YellaCutieToken" target='_blank'>Tweet</LinkEx> */}
                        <LinkEx href="https://etherscan.io/token/0x6011105cE4dA8239C9e4f738F74eeD11611222C3" target='_blank'>Etherscan</LinkEx>
                    </Box>
                </Grid>
                <Grid xs={12} md={4.5} textAlign='center'>
                    <img src={cutieImage} style={{width: '100%',maxWidth: '500px', height:'auto', marginBottom: '-6px',backgroundPosition:"bottom"}} />
                </Grid>
            </Grid>
        </Container>
    </BoxWrapper>
  )
}

export default About