import styled from "@emotion/styled";
import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { CustomGrid, DataImage } from "../../Styles/style";
import zIndex from "@mui/material/styles/zIndex";
import Paragraph from "../Paragraph";
import SectionHeading from "../SectionHeading";
import { MainHeading } from "../OfficialLinks";

const ContinerWraper = styled(Container)`
  width: 100%;
  background: #FFFB31;
  padding: 150px 0 !important;
  @media (max-width: 599px) {
    min-height: auto; 
    padding: 50px 0 !important;
  }
`;


const HowToBuy = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="how-to-buy">
      <Container maxWidth="xl" >
        <Grid
        // data-aos="fade-up"
          container
          sx={{
            display: "flex",  
            gap:{xs:"30px",lg:"50px"}
          }}
        > 
            <CustomGrid
              item
              xs={12}
            >  
              <MainHeading className="textStrok" lineHeight={0.9} fontSize={{xs:"40px",md:"60px",xl:"60px"}} color="white !important" fontWeight={100} fontFamily="DU_B"  textAlign="center" >How To Buy</MainHeading>
            </CustomGrid>  
            <CustomGrid
              item 
              container
              xs={12} 
              sx={{position:"relative"}}
              g="20px"
            > 
            <Divider sx={{display:{xs:"none",lg:"block"},position:"absolute",top:"30px",left:"50%",transform:"translate(-50%,-0px)",background:"#000",height:"2px",width:"60%"}}/> 
               <CustomGrid
              container
              item
              xs={12}  
              md={5.8}
              lg={3.3}
              ai="start"
              fd="column"
              g="20px"
            >  
             <CustomGrid
              item
              xs={12}
            >  
              <Paragraph
                // data-aos="zoom-in"
                variant="h6"  
                fontSize={{xs:"35px",lg:"35px"}}
                sx={{backgroundColor:"#000000",color:"#FFFB31",borderRadius:"30px",padding:"0px 40px",zIndex:"1"}}
              >
               01
              </Paragraph>
            </CustomGrid> 
            <CustomGrid
              item
              xs={12} 
            >  
              <Box sx={{backgroundColor:"#FFFFFF", borderRadius:"20px", minHeight:"220px",padding:"30px 30px 20px",  width:"100%",display:"flex", flexDirection:"column",justifyContent:"start",alignItems:"start",gap:"5px"}}>  
                <Paragraph variant="h5" fontSize={{xs:"25px",lg:"30px"}} sx={{whiteSpace:{xs:"wrap",lg:"nowrap"}}} >   
                DOWNLOAD METAMASK
                </Paragraph>    
                <Paragraph variant="body2" fontSize={{xs:"18px",lg:"23px"}}>   
                Get started by downloading MetaMask, a secure and user-friendly wallet.
                </Paragraph>
              </Box>
            </CustomGrid>
            </CustomGrid> 
            <CustomGrid
              container
              item
              xs={12}  
              md={5.8}
              lg={3.3}
              ai="start"
              fd="column"
              g="20px"
            >  
             <CustomGrid
              item
              xs={12}
            >  
              <Paragraph
                // data-aos="zoom-in" 
                variant="h6"  
                fontSize={{xs:"35px",lg:"35px"}}
                sx={{backgroundColor:"#000000",color:"#FFFB31",borderRadius:"30px",padding:"0px 40px",zIndex:"1"}}
              >
               02
              </Paragraph>
            </CustomGrid> 
            <CustomGrid
              item
              xs={12} 
            >  
              <Box sx={{backgroundColor:"#FFFFFF", borderRadius:"20px",  minHeight:"220px",padding:"30px 30px 20px",  width:"100%",display:"flex", flexDirection:"column",justifyContent:"start",alignItems:"start",gap:"5px"}}>  
                <Paragraph variant="h5" fontSize={{xs:"25px",lg:"30px"}} >   
                SECURE ETHEREUM (ETH)
                </Paragraph>    
                <Paragraph variant="body2" fontSize={{xs:"18px",lg:"23px"}}>   
                Purchase Ethereum (ETH) from a trusted exchange to fund your wallet.
                </Paragraph>
              </Box>
            </CustomGrid>
            </CustomGrid> 
            <CustomGrid
              container
              item
              xs={12}  
              md={5.8}
              lg={3.3}
              ai="start"
              fd="column"
              g="20px"
            >  
             <CustomGrid
              item
              xs={12}
            >  
              <Paragraph 
                // data-aos="zoom-in"
                variant="h6"  
                fontSize="35px"
                sx={{backgroundColor:"#000000",color:"#FFFB31",borderRadius:"30px",padding:"0px 40px",zIndex:"1"}}
              >
               03
              </Paragraph>
            </CustomGrid> 
            <CustomGrid
              item
              xs={12} 
            >  
              <Box sx={{backgroundColor:"#FFFFFF", borderRadius:"20px", minHeight:"220px",padding:"30px 30px 20px",  width:"100%",display:"flex", flexDirection:"column",justifyContent:"start",alignItems:"start",gap:"5px"}}>   
                <Paragraph variant="h5" fontSize={{xs:"25px",lg:"30px"}} >   
                BUY $YELLA
                </Paragraph> 
                <Paragraph variant="body2" fontSize={{xs:"18px",lg:"23px"}}>   
                Once your wallet is funded, head to your preferred exchange to buy $YELLA.
                </Paragraph>
              </Box>
            </CustomGrid>
            </CustomGrid>
            </CustomGrid> 
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default HowToBuy;
