import React, { useState } from 'react';
import {Container, Typography } from '@mui/material';
import BasicAccordion from './Components/AccordianDesign'; 
import styled from "@emotion/styled";   
import DisBG from '../../../assets/DisclaimerBg.png'
import { CustomGrid } from '../../Styles/style';
import { LinkEx } from '../LockerMenu/styles'; 
import tg from '../../../assets/tg.png'
import x from '../../../assets/x.png'
import Paragraph from '../Paragraph';
import { MainHeading } from '../OfficialLinks';


// const Motioncont = motion(Container)
const Blackbg = styled.div` 
  background-image: url(${DisBG});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover; 
    overflow: hidden;
    position:relative;
    z-index: 1;
    min-height:  ${(props) => props.noht ? "auto" : "100vh"} ;
    /* scroll-snap-align:start; */

    
`
const Disclaimer = () => {
   
  const [show, setShow] = useState(false);
  const addressToCopy = "0x6011105ce4da8239c9e4f738f74eed11611222c3";

  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };  

    return (
            <Blackbg noht noclr id="faq"> 
              <Container maxWidth="md" sx={{paddingTop:"150px", paddingBottom:"150px"}}>  
                    <MainHeading className="textStrok" lineHeight={0.9} fontSize={{xs:"40px",md:"60px",xl:"60px"}} color="white" fontWeight={100} fontFamily="DU_B"  textAlign="center" >Disclaimer</MainHeading>
                    <BasicAccordion def1="Legal Disclaimer" def2="Yella Cutie Coin is a meme cryptocurrency and should be considered a high-risk investment. It is not regulated, and its value can be extremely volatile. Always conduct thorough research and consult with a financial advisor before investing." ></BasicAccordion>
                    <BasicAccordion def1="Risk Disclosure" def2="Investing in cryptocurrencies, including Yella Cutie Coin, involves significant risk. You may lose some or all of your investment. Cryptocurrencies are subject to market risks, regulatory changes, and technological vulnerabilities. Only invest what you can afford to lose."></BasicAccordion> 
                    <CustomGrid container fd="column" jc="start" ai="start" g="20px" m="30px 0px" > 
                    <CustomGrid item container  fd="row" jc="space-between" alignItems="start">  
                         <CustomGrid item xs={2} lg={2}>  
                         <LinkEx hc="#000"  sx={{background:"#F4F02E !important"}}href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&inputCurrency=0x6011105ce4da8239c9e4f738f74eed11611222c3" fs="22px" p="8px 20px" fs599="12px" p599="2px 15px" >Buy Now</LinkEx>
                        </CustomGrid> 
                        <CustomGrid item xs={10} lg={10}>  
                        <LinkEx width='100%' maxWidth={800} fs={{xs:"18px",sm:"20px"}} p="8px 15px" p599="10px 10px"  style={{display:'flex',flexDirection:"column",justifyContent: 'space-between'}}><Typography sx={{fontSize:{xs:"15px ",lg:"20px"}}}>Contract Address:</Typography><Typography sx={{fontSize:{xs:"14px ",lg:"20px"}}}>0x6011105ce4da8239c9e4f738f74eed11611222c3</Typography></LinkEx>
                        </CustomGrid>
                    </CustomGrid> 
                    <CustomGrid  item container  fd="row" jc="space-between" alignItems="center" >  
                         <CustomGrid item xs={4} lg={2}>  
                         <Paragraph variant="h5" fontSize={{xs:"25px",lg:"30px"}} sx={{whiteSpace:{xs:"wrap",lg:"nowrap"}}} >   
                         Follow us on:
                        </Paragraph>   
                        </CustomGrid> 
                        <CustomGrid item  xs={3}  lg={1}  >  
                        <LinkEx href="https://t.me/YellaCutieToken" target='blank' className='none'><img style={{width:"38px",height:"40px"}} src={tg} /></LinkEx>
                        <LinkEx href="https://x.com/YellaCutieToken" target='blank' className='none'><img style={{width:"38px",height:"40px"}} src={x} /></LinkEx>
                        </CustomGrid>
                    </CustomGrid> 
                    </CustomGrid>
              </Container>
            </Blackbg>
    );
}
 
export default Disclaimer;