import { Box, Container, Grid, Link, Typography} from '@mui/material'
import React from 'react'
import headerImage from '../../../assets/header.png';
import tg from '../../../assets/tg.png'
import x from '../../../assets/x.png'  
import leftcrw from '../../../assets/crow.png'  
import rightcrw from '../../../assets/crow.png' 
import leftimage from '../../../assets/left.png' 
import rightimage from '../../../assets/right.png' 
import leftcld from '../../../assets/scloud.png' 
import rightcld from '../../../assets/scloud.png'
import { LinkEx } from '../LockerMenu/styles';
import Paragraph from '../Paragraph';
import SectionHeading from '../SectionHeading'; 
import styled from '@emotion/styled';

const BoxWrapper = styled(Box)`
    background-color: #00AEEC;
    background-image: url(${headerImage});
    background-position-x: center;
    background-size: cover;
    min-height: 120vh; 
    @media(max-width : 599px){
        background-size: 200% 50%;
        min-height: 100vh;
        background-repeat: no-repeat !important;
        background-position: bottom !important;
    }
`
const CenteralBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
` 
const MainHeading = styled(Typography)`    
position: absolute; 
top: 60px; 
left: 50%; 
transform: translate(-50%,0%);
@media(max-width: 440px) {
    top: 125px;
    transform: translateX(-50%);
}
`  
const MainHeadingStroke = styled(Typography)`    
position: absolute; 
top: 60px; 
left: 50%; 
transform: translate(-50%,0%);  
-webkit-text-stroke: 2px; 
-webkit-text-stroke-color: black;
@media(max-width: 440px) {
    top: 126px;
    transform: translateX(-50%);
}
` 

const Leftimg = styled.img` 
    position : absolute; 
    top:50px;
    left:100px;  
    width:200px; 
    object-fit: contain;
    @media(max-width : 440px){
    top: 0px !important;
    left: 10px !important;
    width: 112px !important;
    /* object-fit: contain; */
    }
` 
 
const Rightimg = styled.img` 
position : absolute; 
top:50px;
right:0;  
width:200px;
@media(max-width : 440px){
    top: 14px !important;
    right: 0 !important;
    width: 118px !important;
}
` 
const Leftcloud = styled.img` 
    position : absolute; 
    top:200px;
    left:23%;  
    width:75px; 
    object-fit: contain;
    @media(max-width : 599px){
        display: none;
    }
`  

const Rightcloud =  styled.img` 
position : absolute; 
top:80px;
right:22%;  
width:75px; 
object-fit: contain;
@media(max-width : 440px){
    top: 108px !important;
    right: 22% !important;
    width: 47px !important;
    object-fit: contain;
}
`  
 
const Leftcrow = styled.img` 
    position : absolute; 
    top:100px;
    left:18%;  
    width:80px; 
    object-fit: contain;
    @media(max-width : 440px){
        top: 20px !important;
    left: 28% !important;
    width: 53px !important; 
    }
`
const Rightcrow = styled.img` 
    position : absolute; 
    top:200px;
    right:21%;  
    width:70px; 
    object-fit: contain;
    @media(max-width : 440px){
    top: 268px !important;
    right: 13px !important;
    width: 70px !important;
    
    }
`

const Header = () => {
  return (
    <BoxWrapper>
        <Rightimg src={rightimage} alt="leftimage" /> 
        <Container maxWidth="xl" sx={{position:'relative'}}> 
        <MainHeading lineHeight={0.9} fontSize={{xs:"68px",md:"90px",xl:"110px"}} color="#FFFB31" whiteSpace={{xs:"wrap",lg:"nowrap"}} fontWeight={100} fontFamily="DU_B"  textAlign="left" >Yella Cutie</MainHeading>  
        <MainHeadingStroke lineHeight={0.9} fontSize={{xs:"68px",md:"90px",xl:"110px"}} color="#FFFB31"whiteSpace={{xs:"wrap",lg:"nowrap"}}  fontWeight={100} fontFamily="DU_B"  textAlign="left" >Yella Cutie</MainHeadingStroke> 
        <Leftimg src={leftimage} alt="leftimage" /> 
        <Leftcloud src={leftcld} alt="leftimage" />  
        <Rightcloud src={rightcld} alt="leftimage" />   
        <Leftcrow src={leftcrw} alt="leftimage" /> 
        <Rightcrow src={rightcrw} alt="leftimage" /> 
            <Grid container justifyContent='center' sx={{paddingTop:{xs:"150px",lg:'70px'}}}>
                <Grid md={4} sm={12} marginTop={16} justifyContent='center'>
                    {/* <Typography maxWidth={300} textAlign='center' >
                        Hi I'm Yella Cutie<br />I'm the coolest, chillest, and funniest one of the Matt Furie boys club collection. Welcome to my world. 
                    </Typography>  */}  
                    
                    <Box display={{xs:"none",lg:"flex"}} flexDirection="column" justifyContent="center" alignItems="center" gap="5px">  
                    <Paragraph textAlign="center" fontSize={{xs:"16px",lg:"25px"}} sx={{backgroundColor:"white",borderRadius:"20px",maxWidth:"fit-content",padding:"0px 15px",}}>  
                    Hi I'm Yella Cutie.
                    </Paragraph> 
                    <Paragraph textAlign="center" fontSize={{xs:"16px",lg:"25px"}}  sx={{backgroundColor:"white",borderRadius:"20px",maxWidth:"fit-content",padding:"0px 15px",whiteSpace:{xs:"", lg:"nowrap"}}}>  
                    I'm the coolest, chillest, and funniest one of the Matt
                    </Paragraph> 
                    <Paragraph textAlign="center" fontSize={{xs:"16px",lg:"25px"}}  sx={{backgroundColor:"white",borderRadius:"20px",maxWidth:"fit-content",padding:"0px 15px",whiteSpace:{xs:"", lg:"nowrap"}}}>  
                    Furie boys club collection. Welcome to my world. 
                    </Paragraph> 


                    </Box> 
                    <Box display={{xs:"flex",lg:"none"}} flexDirection="column" justifyContent="center" alignItems="center" gap="5px">  
                    <Paragraph textAlign="center" fontSize={{xs:"`18px`",lg:"25px"}} sx={{backgroundColor:"white",borderRadius:"20px",maxWidth:"fit-content",padding:"0px 5px",}}>  
                    Hi I'm Yella Cutie.
                    </Paragraph>  
                    <Paragraph textAlign="center" fontSize={{xs:"18px",lg:"25px"}}  sx={{backgroundColor:"white",borderRadius:"20px",maxWidth:"fit-content",padding:"0px 5px",whiteSpace:{xs:"", lg:"nowrap"}}}>  
                    I'm the coolest, chillest, and funniest 
                    </Paragraph> 
                    <Paragraph textAlign="center" fontSize={{xs:"18px",lg:"25px"}}  sx={{backgroundColor:"white",borderRadius:"20px",maxWidth:"400px",padding:"0px 5px",whiteSpace:{xs:"nowrap", lg:"nowrap"}}}>  
                    one of the Matt Furie boys club collection.
                    </Paragraph> 
                    <Paragraph textAlign="center" fontSize={{xs:"18px",lg:"25px"}}  sx={{backgroundColor:"white",borderRadius:"20px",width:"100%",padding:"0px 5px",whiteSpace:{xs:"", lg:"nowrap"}}}>  
                     Welcome to my world. 
                    </Paragraph>
                    </Box>
              
                    <CenteralBox marginTop={3} sx={{alignItems:"start !important"}}>
                        
                        <LinkEx href="https://x.com/YellaCutieToken" target='blank' className='none'><img src={x} style={{width: "52px", height: "55px"}}  /></LinkEx> 
                        <LinkEx href="https://t.me/YellaCutieToken" target='blank' className='none'><img  src={tg} style={{width: "52px", height: "55px"}} /></LinkEx>
                        <LinkEx href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&inputCurrency=0x6011105ce4da8239c9e4f738f74eed11611222c3">Buy Now</LinkEx>
                    </CenteralBox>
                </Grid>
            </Grid>
        </Container>
    </BoxWrapper>
  )
}

export default Header