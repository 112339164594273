import React, { useState } from 'react'


import headerImage   from '../../../assets/tokens.jpg';
import { Box, Container, Grid, Typography } from '@mui/material';
import emotionStyled from '@emotion/styled';
import { LinkEx } from '../LockerMenu/styles';
import { MainHeading } from '../OfficialLinks';

const BoxWrapper = emotionStyled(Box)`
    background-color: #00AEEC;
    background-image: url(${headerImage});
    background-position-x: center;
    background-size: cover;
    min-height: 100vh;
    background-repeat: no-repeat;
    padding: 100px 0;
`
const BoxEx = emotionStyled(Box)`
    border-radius: 20px;
    padding: 30px 20px;
    border: 2px solid black;
    margin-right: 10px;
    margin-top: 20px;
`


function Tokenomics() { 

    const [show, setShow] = useState(false);
    const addressToCopy = "0x6011105ce4da8239c9e4f738f74eed11611222c3";
  
    const handleCopy = () => {
      navigator.clipboard.writeText(addressToCopy);
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }; 


  return (
    <BoxWrapper>
        <Container maxWidth="lg">
            <Grid container>
                <Grid xs={12} md={6}>
                    <MainHeading variant='h2' fontWeight='bold' fontSize={{xs:"40px",md:"60px",xl:"60px"}} color="#FFFB31" marginBottom={2}>Tokenomics</MainHeading>
                    <Typography fontFamily="PatrickHand" fontSize="28px" maxWidth={500}>Tokenomics you just can't beat.<br />Now ain't that a treat!</Typography>
                    <LinkEx width='100%' maxWidth={800} marginTop={3} style={{display:'flex',flexDirection:"column", justifyContent: 'space-between'}}><Typography sx={{fontSize:{xs:"15px ",lg:"20px"},fontFamily:"PatrickHand"}}>Contract Address:</Typography><Typography sx={{fontSize:{xs:"15px ",lg:"20px"},fontFamily:"PatrickHand"}}>0x6011105ce4da8239c9e4f738f74eed11611222c3</Typography></LinkEx>
                    <Box sx={{display: 'flex',flexWrap:{xs:"wrap",lg:"nowrap"}}}>
                        <BoxEx width={115} style={{background:'#31FF68'}} >
                            <Typography fontFamily="PatrickHand"  variant='h5'>Taxes</Typography>
                            <Typography fontFamily="PatrickHand"  variant='h5' fontWeight='bold'>0/0</Typography>
                        </BoxEx>
                        <BoxEx width={230} style={{background:'#BFBFBF'}}>
                            <Typography fontFamily="PatrickHand"  variant='h5'>Contract</Typography>
                            <Typography fontFamily="PatrickHand"  variant='h5' fontWeight='bold'>Renounced</Typography>
                        </BoxEx>
                        <BoxEx width={130} style={{background:'#FFC831'}}>
                            <Typography fontFamily="PatrickHand"  variant='h5'>Liquidity</Typography>
                            <Typography fontFamily="PatrickHand"  variant='h5' fontWeight='bold'>Burned</Typography>
                        </BoxEx>
                    </Box>
                    <BoxEx width="100%" maxWidth='495px' style={{background:'#F4F02E'}}>
                        <Typography fontFamily="PatrickHand"  variant='h5'>Liquidity</Typography>
                        <Typography fontFamily="PatrickHand"  variant='h5' fontWeight='bold'>Burned</Typography>
                    </BoxEx>
                </Grid>
            </Grid>
        </Container>
    </BoxWrapper>
  )
}

export default Tokenomics