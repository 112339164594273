import { Typography } from '@mui/material'
import React from 'react'


const Paragraph = (props) => {
    const {children} = props
  return (
    <Typography color="#000000" fontFamily="PatrickHand" variant='body2' textAlign="left"  {...props}>
        {children}
    </Typography>
  )
}

export default Paragraph